import { Site } from "@inrange/building-manager-api-client/models-site";
import React from "react";
import { currencySymbol } from "../../formatting/currency-utils";
import { sqmToSiteAreaUnitRaw } from "../../formatting/formatArea";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import {
  formatUnits,
  formatUnitsToNearestTen,
} from "../../formatting/formatKiloUnits";
import formatMass from "../../formatting/formatMass";
import formatPercentage from "../../formatting/formatPercentage";
import { formatBattery } from "../../formatting/formatting-utils";
import Grid from "../layout/Grid";
import {
  LandlordSiteMetricValues,
  MetricCardsRow,
  SiteMetricCard,
} from "./index";

export const LandlordSiteMetricCards: React.FC<{
  site: Site;
  metrics: LandlordSiteMetricValues;
  currencyCode: string;
}> = ({ site, metrics, currencyCode }) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitted half hourly demand data improves accuracy.";

  return (
    <MetricCardsRow>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"170px"}
      >
        <SiteMetricCard
          title="Revenue year 1"
          value={formatCurrencyToNearestTen(
            metrics.revenueYearOne,
            currencyCode,
            0,
            false
          )}
          tooltipText="Revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Lifetime revenue"
          value={formatCurrencyToNearestTen(
            metrics.lifetimeRevenue,
            currencyCode,
            0,
            false
          )}
          tooltipText="Lifetime revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"192px"}
      >
        <SiteMetricCard
          title="Up front investment"
          value={formatCurrencyToNearestTen(
            metrics.initialInvestment,
            currencyCode,
            0,
            false
          )}
          tooltipText="Up front investment is the cost to procure and install the on-site system, as well as accounts for enabling works costs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Total project cost"
          value={formatCurrencyToNearestTen(
            metrics.totalProjectCost,
            currencyCode,
            0,
            false
          )}
          tooltipText="Total project cost accounts for up front investment as well as lifetime costs like operations, maintenance, and insurance."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <SiteMetricCard
        title="IRR"
        value={formatPercentage(metrics.irr)}
        tooltipText="IRR is calculated based on expected monthly revenue as it relates to total project cost."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Payback"
        value={metrics.payback ? metrics.payback + " months" : "N/A"}
        tooltipText="Payback is the number of months required to pay back the up front investment given the expected annual revenue."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title={`Revenue per ${site.areaUnit}²`}
        value={`${currencySymbol(currencyCode)}
                ${(
                  metrics.revenueYearOne /
                  sqmToSiteAreaUnitRaw(
                    metrics.totalBuildingAreaM2,
                    site.areaUnit
                  )
                ).toFixed(2)}`}
        tooltipText="Estimated revenue generated in the first year divided by the size of the roof in ft²."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="On-site demand year 1"
        value={formatUnitsToNearestTen(metrics.onSiteDemandYearOne, "kWh")}
        tooltipText={`Estimated demand for the on-site tenant in the first year.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="Generation potential year 1"
        value={formatUnitsToNearestTen(
          metrics.generationPotentialYearOne,
          "kWh"
        )}
        tooltipText="Estimated potential for energy generated by the on-site system in the first year."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        note={
          site.installedCapacity > 0
            ? `From ${formatUnits(site.installedCapacity, "kWp", 1)} system`
            : ""
        }
      />
      <SiteMetricCard
        title="On-site generation consumed by tenant"
        value={formatPercentage(metrics.onSiteGenerationConsumed)}
        tooltipText={`Projected percentage of generation that will be consumed by the occupational tenant.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        note={
          site.costInputsBattery.batterySpec
            ? `With a ${formatBattery(site.costInputsBattery.batterySpec)} battery`
            : ""
        }
      />
      <SiteMetricCard
        title="Emissions avoided year 1"
        value={[
          `${formatMass(metrics.emissionsAvoidedYearOne)} CO2e`,
          `${formatPercentage(metrics.cfeScore)} CFE`,
        ]}
        tooltipText="Emissions avoided in the first year after installation where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each this site location."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        $minWidth={"140px"}
      />
    </MetricCardsRow>
  );
};
