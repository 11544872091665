import ascending from "../../images/icons/ascending.svg";
import descending from "../../images/icons/descending.svg";

export const getSortingIcon = (
  sortColumn: string,
  sortValue: string,
  sortAscending: boolean
) => {
  if (sortColumn === sortValue) {
    return sortAscending ? (
      <img
        src={ascending}
        alt="sortIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ) : (
      <img
        src={descending}
        alt="sortIcon"
        style={{ width: "16px", height: "16px" }}
      />
    );
  }
  return <div style={{ width: "16px", height: "16px" }}></div>;
};
