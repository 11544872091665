import { calcCfeScore } from "@inrange/calculations/calcCfeScore";
import {
  getScoreBySiteID,
  inrangeScoreStatusColor,
  siteStatusMap,
} from "@inrange/theme-components";
import React from "react";
import styled from "styled-components";
import { formatArea, sqmToSiteAreaUnitRaw } from "../../formatting/formatArea";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import formatMass from "../../formatting/formatMass";
import roundUpPayback from "../../utils/roundUpPayback";
import GenerationHeadroomCell from "./GenerationHeadroomCell";
import SiteLink from "./SiteLink";
import SiteNameAddressData from "./SiteNameAddressData";
import TableCellData from "./TableCellData";

import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { SiteScore } from "@inrange/theme-components";
import { currencySymbol } from "../../formatting/currency-utils";
import toNearestTen from "../../formatting/toNearestTen";

interface LandlordSiteRowProps {
  site: OrgSiteListEntry;
  organisation: Organisation;
  mode: string;
  leaseRevenue: number;
  licenseRevenue: number;
  irr: number;
  payback: number | string;
  orgAreaUnit: string;
  displayInRangeScoreCol: boolean;
  allSitesNotStarted: boolean;
  showTenantColumn: boolean;
}

const LandlordSiteRow: React.FC<LandlordSiteRowProps> = ({
  site,
  organisation,
  mode,
  leaseRevenue,
  licenseRevenue,
  irr,
  payback,
  orgAreaUnit,
  displayInRangeScoreCol,
  allSitesNotStarted,
  showTenantColumn,
}) => {
  const carbonEmissions = site.calculations.emissionsAvoided.totalAvoidance;
  const carbonFreeEnergy = calcCfeScore(site.energyFlowAnnual) * 100;
  let tenants: string[] = [];
  if (showTenantColumn) {
    tenants = site.siteOwnerships
      .filter((ownership) => ownership.ownership === "tenant")
      .map((tenant) => tenant.name!);
  }
  const tenantString =
    tenants.length > 3
      ? tenants.slice(0, 3).join("\r\n") + "..."
      : tenants.join("\r\n");
  return (
    <LandlordSiteRowView
      site={site}
      mode={mode}
      carbonEmissions={carbonEmissions}
      carbonFreeEnergy={carbonFreeEnergy}
      payback={payback}
      rooftopArea={site.totalBuildingArea}
      orgID={organisation.id}
      irr={irr}
      licenseRevenue={licenseRevenue}
      leaseRevenue={leaseRevenue}
      tenantString={tenantString}
      showTenantColumn={showTenantColumn}
      orgAreaUnit={orgAreaUnit}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
      score={getScoreBySiteID(organisation.id, site)}
    />
  );
};

interface LandlordSiteRowViewProps {
  site: OrgSiteListEntry;
  mode: string;
  irr: number;
  payback: number | string;
  licenseRevenue: number;
  leaseRevenue: number;
  tenantString: string;
  showTenantColumn: boolean;
  carbonEmissions: number;
  carbonFreeEnergy: number;
  rooftopArea: number;
  orgID: string;
  orgAreaUnit: string;
  displayInRangeScoreCol: boolean;
  allSitesNotStarted: boolean;
  score?: number;
}

const LandlordSiteRowView: React.FC<LandlordSiteRowViewProps> = (props) => {
  if (props.displayInRangeScoreCol) {
    return <LandlordSiteRowViewInrangeScoreTrue {...props} />;
  }
  return <LandlordSiteRowViewInrangeScoreFalse {...props} />;
};

export default LandlordSiteRow;

const LandlordSiteRowViewInrangeScoreTrue: React.FC<
  LandlordSiteRowViewProps
> = ({
  site,
  mode,
  irr,
  payback,
  licenseRevenue,
  leaseRevenue,
  tenantString,
  showTenantColumn,
  carbonEmissions,
  carbonFreeEnergy,
  rooftopArea,
  orgID,
  orgAreaUnit,
  score,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      <td style={{ width: "170px" }}>
        {site.operationalStatus === "notStarted" ? (
          <SiteScore
            siteScore={score!}
            statusColor={inrangeScoreStatusColor[score!]}
          />
        ) : (
          <StatusContainer>
            {siteStatusMap[site.operationalStatus].map((status, i) => {
              return <span key={i}>{status}</span>;
            })}
          </StatusContainer>
        )}
      </td>
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={orgID}
          />
        </TableCellData>
      </td>

      {mode === "license" && (
        <LicenseRows
          irr={irr}
          payback={payback}
          licenseRevenue={licenseRevenue}
          currencyCode={site.currencyCode}
        />
      )}
      {mode === "lease" && (
        <LeaseRows
          rooftopArea={rooftopArea}
          leaseRevenue={leaseRevenue}
          displayInRangeScoreCol={true}
          tenantString={tenantString!}
          showTenantColumn={showTenantColumn!}
          orgAreaUnit={orgAreaUnit}
          currencyCode={site.currencyCode}
        />
      )}
      <td>
        <TableCellData>{formatMass(carbonEmissions)}</TableCellData>
        <TableCellData>{carbonFreeEnergy.toFixed(1)}% CFE</TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={orgID} />
      </td>
    </tr>
  );
};

const LandlordSiteRowViewInrangeScoreFalse: React.FC<
  LandlordSiteRowViewProps
> = ({
  site,
  mode,
  irr,
  payback,
  licenseRevenue,
  leaseRevenue,
  carbonEmissions,
  carbonFreeEnergy,
  rooftopArea,
  orgID,
  orgAreaUnit,
  allSitesNotStarted,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      {!allSitesNotStarted && (
        <td style={{ width: "170px" }}>
          <StatusContainer>
            {site.operationalStatus === "notStarted"
              ? "Feasibility study"
              : siteStatusMap[site.operationalStatus].map((status, i) => {
                  return <span key={i}>{status}</span>;
                })}
          </StatusContainer>
        </td>
      )}
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={orgID}
          />
        </TableCellData>
      </td>
      <td>
        <GenerationHeadroomCell site={site} />
      </td>
      {mode === "license" && (
        <LicenseRows
          irr={irr}
          payback={payback}
          licenseRevenue={licenseRevenue}
          currencyCode={site.currencyCode}
        />
      )}
      {mode === "lease" && (
        <LeaseRows
          rooftopArea={rooftopArea}
          leaseRevenue={leaseRevenue}
          displayInRangeScoreCol={false}
          orgAreaUnit={orgAreaUnit}
          currencyCode={site.currencyCode}
        />
      )}
      <td>
        <TableCellData>{formatMass(carbonEmissions)}</TableCellData>
        <TableCellData>{carbonFreeEnergy.toFixed(1)}% CFE</TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={orgID} />
      </td>
    </tr>
  );
};

interface LicenseRowsProps {
  irr: number;
  payback: number | string;
  licenseRevenue: number;
  currencyCode: string;
}

const LicenseRows: React.FC<LicenseRowsProps> = ({
  irr,
  payback,
  licenseRevenue,
  currencyCode,
}) => {
  return (
    <>
      <td>{(irr * 100).toFixed(1)}%</td>
      <td>{roundUpPayback(payback)} months</td>
      <td>
        {formatCurrencyToNearestTen(licenseRevenue, currencyCode, 0, false)}
      </td>
    </>
  );
};

interface LeaseRowsProps {
  rooftopArea: number;
  leaseRevenue: number;
  displayInRangeScoreCol: boolean;
  tenantString?: string;
  showTenantColumn?: boolean;
  orgAreaUnit: string;
  currencyCode: string;
}

const LeaseRows: React.FC<LeaseRowsProps> = ({
  rooftopArea,
  leaseRevenue,
  displayInRangeScoreCol,
  tenantString,
  showTenantColumn,
  orgAreaUnit,
  currencyCode,
}) => {
  return (
    <>
      {displayInRangeScoreCol ? null : (
        <td>
          {formatArea(
            toNearestTen(sqmToSiteAreaUnitRaw(rooftopArea, orgAreaUnit)),
            orgAreaUnit
          )}
        </td>
      )}
      <td>
        {formatCurrencyToNearestTen(leaseRevenue, currencyCode, 0, false)}
      </td>
      <td>
        {currencySymbol(currencyCode)}
        {(
          leaseRevenue / sqmToSiteAreaUnitRaw(rooftopArea, orgAreaUnit)
        ).toFixed(2)}
      </td>
      {displayInRangeScoreCol && showTenantColumn ? (
        <td style={{ whiteSpace: "pre" }}>{tenantString}</td>
      ) : null}
    </>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
