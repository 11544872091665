import React from "react";
import styled from "styled-components";
import SimpleButton from "../buttons/SimpleButton";
import { LeaseMetrics, LicenseMetrics } from "./Metrics";
import { ProjectCosts } from "./ProjectCosts";
import { ComparisonList, Header, SubHeader } from "./Text";

interface ComparisonItemProps {
  modelName: string;
  subheaderText: string;
  comparisonListItems: string[];
  hardwareCost: number;
  installationCost: number;
  enablingWorksCosts: number;
  additionalCosts?: number;
  metrics: JSX.Element;
  setInvestmentModel: () => void;
  currencyCode: string;
}

export const ComparisonWrapper = styled.div`
  display: flex;
`;

export const ComparisonItem: React.FC<ComparisonItemProps> = ({
  modelName,
  subheaderText,
  comparisonListItems,
  hardwareCost,
  installationCost,
  enablingWorksCosts,
  additionalCosts,
  metrics,
  setInvestmentModel,
  currencyCode,
}) => {
  return (
    <ComparisonItemWrapper>
      <Header modelName={modelName} />
      <SubHeader text={subheaderText} />
      <ComparisonList listItemTexts={comparisonListItems} />
      {metrics}
      <ProjectCosts
        hardwareCost={hardwareCost}
        installationCost={installationCost}
        enablingWorksCosts={enablingWorksCosts}
        additionalCosts={additionalCosts}
        currencyCode={currencyCode}
      />
      <Footer>
        <SimpleButton
          label={`Select ${modelName}`}
          onClick={setInvestmentModel}
        />
      </Footer>
    </ComparisonItemWrapper>
  );
};

const ComparisonItemWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 8px 16px;
  //padding: 8px 54px;
  margin: 0 8px;
  flex-grow: 1;
`;

const Footer = styled.div`
  margin: 32px 0 16px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LeaseComparisonItem: React.FC<{
  annualRevenue: number;
  additionalCosts?: number;
  totalBuildingAreaM2: number;
  setInvestmentModel: () => void;
  currencyCode: string;
  areaUnit: string;
}> = ({
  annualRevenue,
  additionalCosts,
  totalBuildingAreaM2,
  setInvestmentModel,
  currencyCode,
  areaUnit,
}) => {
  const metrics = (
    <LeaseMetrics
      totalBuildingAreaM2={totalBuildingAreaM2}
      annualRevenue={annualRevenue}
      currencyCode={currencyCode}
      areaUnit={areaUnit}
    />
  );
  return (
    <ComparisonItem
      modelName="Lease"
      subheaderText="ZERO up front investment, InRange owns the asset"
      comparisonListItems={[
        "InRange is responsible for all aspects of the operation, maintenance, and distribution of electrons",
        "Landlord provides InRange with the rights to install, operate and maintain the solar panel installation",
        "Landlords receive revenue from rent and profit-share generated from the sale of electrons",
      ]}
      hardwareCost={0}
      installationCost={0}
      enablingWorksCosts={0}
      additionalCosts={additionalCosts}
      metrics={metrics}
      setInvestmentModel={setInvestmentModel}
      currencyCode={currencyCode}
    />
  );
};

interface LicenseComparisonItemProps {
  irr?: number;
  paybackMonths?: number;
  annualRevenue: number;
  hardwareCosts: number;
  installationCosts: number;
  enablingWorksCosts: number;
  additionalCosts?: number;
  initialInvestment: number;
  setInvestmentModel: () => void;
  currencyCode: string;
}

export const LicenseComparisonItem: React.FC<LicenseComparisonItemProps> = ({
  irr,
  paybackMonths,
  annualRevenue,
  hardwareCosts,
  installationCosts,
  enablingWorksCosts,
  initialInvestment,
  additionalCosts,
  setInvestmentModel,
  currencyCode,
}) => {
  const metrics = (
    <LicenseMetrics
      irr={irr}
      paybackMonths={paybackMonths}
      initialInvestment={initialInvestment}
      annualRevenue={annualRevenue}
      currencyCode={currencyCode}
    />
  );

  return (
    <ComparisonItem
      modelName="License"
      subheaderText="Up front investment, you own the asset"
      comparisonListItems={[
        "InRange is responsible for all aspects of the operation, maintenance, and distribution of electrons",
        "Landlord provides them with the rights to operate and maintain the solar panel installation",
        "Landlords receive revenue from rent and profit-share generated from the sale of electrons",
      ]}
      hardwareCost={hardwareCosts}
      installationCost={installationCosts}
      enablingWorksCosts={enablingWorksCosts}
      additionalCosts={additionalCosts}
      metrics={metrics}
      setInvestmentModel={setInvestmentModel}
      currencyCode={currencyCode}
    />
  );
};
