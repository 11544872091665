export const formatArea = (area: number, areaUnit: string): string => {
  return new Intl.NumberFormat("en-GB", {}).format(area) + areaUnit + "²";
};

export const sqmToSqFtRaw = (meters: number): number => {
  return meters * 10.7639104167;
};

export const sqmToSiteAreaUnitRaw = (
  meters: number,
  areaUnit: string
): number => {
  if (areaUnit === "m") {
    return meters;
  }
  return meters * 10.7639104167;
};
