import {
  AggregateEnergyFlow,
  Calculations,
  CostInputsBattery,
  CostInputsPv,
  FinancialModelScenarios,
  ProjectCosts,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { calcCfeScore } from "@inrange/calculations/calcCfeScore";
import { Column, SummaryBoxMetric } from "@inrange/theme-components";
import { ChartData } from "@inrange/theme-components/charts";
import {
  formatCurrencyToNearestTen,
  formatUnitsToNearestTen,
  tariffToFractionalCurrency,
} from "@inrange/theme-components/formatting";
import { InheritColorLink } from "@inrange/theme-components/marketplace";
import {
  LandlordSiteMetricValues,
  TenantSiteMetricValues,
} from "@inrange/theme-components/site";
import { Link } from "react-router-dom";
import {
  getProposalCostOfEnergyProcuredChartData,
  getProposalEnergySoldChartData,
  getProposalRevenueChartData,
} from "../dashboard/chartDataFormatting";

interface SharedSite {
  id: string;
  name: string;
}

interface SummaryBox {
  header: string;
  metrics: SummaryBoxMetric[] | SummaryBoxMetric[][];
  grid: boolean;
  summaryChildren?: JSX.Element | (JSX.Element | undefined)[];
}

interface ChartDefinition {
  chartData: ChartData[];
  header: string;
  tooltipText: string;
  keys: string[];
  currency: boolean;
  barColors?: string[];
}

interface ProposalSummaryMetrics {
  metricCards?: TenantSiteMetricValues | LandlordSiteMetricValues;
  charts: ChartDefinition[];
  summaryBoxes: SummaryBox[];
}

const generateProposalSummaryMetrics = (
  proposalType: string,
  isGeneratingSite: boolean,
  site: Site,
  investmentModel: string,
  setInvestmentModel: (model: string) => void,
  hhDataUploaded: boolean,
  sharedSitesWithLandlord: SharedSite[],
  sellEnergyBlock: JSX.Element | undefined,
  generateBuyEnergyBlock: (unmetDemand: boolean) => JSX.Element | undefined,
  orgId: string,
  setShowEnergyCurvesModal: (showModal: boolean) => void,
  isSiteCanSell: boolean
): ProposalSummaryMetrics => {
  if (investmentModel === "none")
    return {
      metricCards: undefined,
      charts: [],
      summaryBoxes: [],
    };

  const {
    projectCosts,
    energyFlowAnnual: forecastedEnergyFlowAnnual,
    networkImportTariff,
    marketTariff,
    totalBuildingArea,
    calculations,
    siteOwnerships,
  } = site;

  const hasOnsiteBehindMeter = forecastedEnergyFlowAnnual.behindMeter > 0;
  const numNetworkImportSites = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  ).length;
  const numNetworkExportSites =
    site.sdmMatches.filter((match) => match.sellerId === site.id).length +
    (site.energyFlowAnnual.exported > 0 ? 1 : 0);

  let behindTheMeterTariff = site.tenantTariff;

  const forecastedBehindMeter = forecastedEnergyFlowAnnual.behindMeter;
  const forecastedNetworkImport = forecastedEnergyFlowAnnual.networkImport;
  const forecastedInRangeProcurement = forecastedEnergyFlowAnnual.procurement;

  if (proposalType === "ownerOccupier" && investmentModel === "license") {
    behindTheMeterTariff = 0;
  }
  const forecastedBehindMeterCost =
    forecastedBehindMeter * behindTheMeterTariff;
  const forecastedNetworkImportCost =
    forecastedNetworkImport * networkImportTariff;
  const forecastedInRangeProcurementCost =
    forecastedBehindMeterCost + forecastedNetworkImportCost;

  const otherInvestmentModel =
    investmentModel === "license" ? "lease" : "license";
  const projectCostLeaseLicenseToggleText =
    investmentModel === "license"
      ? " for zero hardware, installation, and enabling works costs."
      : " to own the installation and earn more revenue.";

  const projectCostsLeaseLicenseToggle = (
    <Column
      $margin={"0 15px 15px 15px"}
      $padding={"0 8px"}
      $justifyContent={"flex-end"}
    >
      <div
        style={{
          fontSize: "16px",
        }}
      >
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            setInvestmentModel(otherInvestmentModel);
            document.body.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Switch to {otherInvestmentModel}
        </span>
        <span>{projectCostLeaseLicenseToggleText}</span>
      </div>
    </Column>
  );
  const sharedSitesWithLandlordList = sharedSitesWithLandlord.length > 0 && (
    <Column
      $margin={"0 15px 15px 15px"}
      $padding={"0 8px"}
      $justifyContent={"flex-end"}
    >
      <div style={{ fontWeight: 550, fontSize: 18 }}>
        Other sites with landlord:
      </div>
      <ul style={{ marginTop: 0, paddingLeft: 20 }}>
        {sharedSitesWithLandlord.slice(0, 3).map((site, index) => {
          return (
            <li key={index}>
              <Link
                to={`/org/${orgId}/site/${site.id}`}
                style={{ color: "white" }}
                onClick={() => {
                  document.body.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                {site.name}
              </Link>
            </li>
          );
        })}
        {sharedSitesWithLandlord.length > 3 && (
          <li>Plus {sharedSitesWithLandlord.length - 3} more sites...</li>
        )}
      </ul>
    </Column>
  );

  const isTenant = proposalType === "tenant";
  const isOwnerOccupier = proposalType === "ownerOccupier";
  const isOwnerOccupierLicense =
    isOwnerOccupier && investmentModel === "license";

  const hhDemandDataString = hhDataUploaded
    ? ""
    : " Submitting your half hourly data will increase accuracy.";

  const costOfEnergyProcuredChart = {
    chartData: getProposalCostOfEnergyProcuredChartData(
      site,
      isOwnerOccupierLicense
    ),
    header: "Cost of energy procured by month",
    tooltipText: `The forecasted cost of energy procured based on ${
      isGeneratingSite ? "on-site and network tariffs" : "the network tariff"
    } and forecasted demand.${hhDemandDataString}`,
    keys:
      isGeneratingSite && !isOwnerOccupierLicense
        ? ["On-site behind the meter", "From InRange network"]
        : ["From InRange network"],
    currency: true,
  };
  const generateTariffMarketplaceBlock = (proposalType: string) => {
    switch (proposalType) {
      case "ownerOccupier":
        return [generateBuyEnergyBlock(false), sellEnergyBlock];
      case "landlord":
        return sellEnergyBlock;
      default:
        // "tenant"
        if (!isGeneratingSite) {
          return generateBuyEnergyBlock(false);
        }
    }
    return undefined;
  };

  const proposalSummaryMetrics: ProposalSummaryMetrics = {
    metricCards: generateSiteMetricValues(
      proposalType,
      hhDataUploaded,
      marketTariff,
      site.financialModels,
      projectCosts,
      forecastedEnergyFlowAnnual,
      forecastedInRangeProcurement,
      forecastedInRangeProcurementCost,
      investmentModel,
      totalBuildingArea,
      calculations,
      hasOnsiteBehindMeter,
      numNetworkImportSites,
      numNetworkExportSites
    ),
    charts:
      proposalType === "tenant"
        ? [costOfEnergyProcuredChart]
        : [
            {
              chartData: getProposalRevenueChartData(
                site,
                investmentModel,
                isOwnerOccupierLicense
              ),
              header: "Revenue by month",
              tooltipText:
                "The forecasted revenue generated from energy sold after the given revenue share during a given month, where energy be sold on-site or exported to the InRange network, at the relevant tariffs. Does not include VAT.",
              keys: isOwnerOccupierLicense
                ? ["Exported to network"]
                : ["On-site to tenant", "Exported to network"],
              barColors: isOwnerOccupierLicense ? ["#2779A7"] : undefined,
              currency: true,
            },
            {
              chartData: getProposalEnergySoldChartData(site),
              header: "Energy sold by month",
              tooltipText:
                "The forecasted amount of energy sold in kWh during a given month, where energy can be sold on-site or exported to the InRange network.",
              keys: ["On-site to tenant", "Exported to network"],
              currency: false,
            },
          ],
    summaryBoxes: [
      {
        header: "Tariffs",
        metrics: generateTariffMetrics(
          site,
          behindTheMeterTariff,
          proposalType,
          isGeneratingSite
        ),
        grid: true,
        summaryChildren: generateTariffMarketplaceBlock(proposalType),
      },
    ],
  };

  if (isOwnerOccupier) {
    proposalSummaryMetrics.charts.push(costOfEnergyProcuredChart);
  }

  if (isTenant) {
    // Tenant
    const procurementBySourceSummary: SummaryBox = {
      header: "Procurement by source",
      metrics: generateProcurementMetricsSource(
        forecastedEnergyFlowAnnual,
        isGeneratingSite,
        numNetworkImportSites,
        setShowEnergyCurvesModal
      ),
      grid: false,
      summaryChildren: generateBuyEnergyBlock(true),
    };

    if (isGeneratingSite) {
      // Tenant: Generating
      proposalSummaryMetrics.summaryBoxes.push(
        {
          header: "Procurement in year 1",
          metrics: generateProcurementMetricsMonetary(
            site.currencyCode,
            forecastedBehindMeterCost,
            forecastedNetworkImportCost,
            forecastedInRangeProcurementCost
          ),
          grid: false,
          summaryChildren: generateBuyEnergyBlock(false),
        },
        procurementBySourceSummary
      );

      const landlordName = siteOwnerships.find(
        (ownership) => ownership.ownership === "landlord"
      )?.name;

      landlordName &&
        proposalSummaryMetrics.summaryBoxes.push({
          header: "Landlord",
          metrics: generateTenantLandlord(landlordName),
          grid: false,
          summaryChildren: sharedSitesWithLandlordList || undefined,
        });
    } else {
      // Tenant: Procurement only
      proposalSummaryMetrics.summaryBoxes.push(procurementBySourceSummary);
    }
  } else {
    // Landlord
    proposalSummaryMetrics.summaryBoxes.push(
      {
        header: "Project costs",
        metrics: generateProjectCostsMetrics(
          site.currencyCode,
          site.costInputsPv,
          site.costInputsBattery,
          projectCosts,
          investmentModel
        ),
        grid: false,
        summaryChildren:
          site.operationalStatus === "notStarted"
            ? projectCostsLeaseLicenseToggle
            : undefined,
      },
      {
        header: "Energy sale by destination",
        metrics: generateEnergySaleMetrics(
          forecastedEnergyFlowAnnual,
          numNetworkExportSites,
          setShowEnergyCurvesModal,
          isSiteCanSell
        ),
        grid: false,
        summaryChildren: sellEnergyBlock,
      }
    );
  }

  return proposalSummaryMetrics;
};

export default generateProposalSummaryMetrics;

const generateSiteMetricValues = (
  proposalType: string,
  hhDataUploaded: boolean,
  marketTariff: number,
  financialModels: FinancialModelScenarios,
  projectCosts: ProjectCosts,
  forecastedEnergyFlowAnnual: AggregateEnergyFlow,
  forecastedInRangeProcurement: number,
  forecastedInRangeProcurementCost: number,
  investmentModel: string,
  totalBuildingArea: number,
  calculations: Calculations,
  hasOnsiteBehindMeter: boolean,
  numNetworkImportSites: number,
  numNetworkExportSites: number
): TenantSiteMetricValues | LandlordSiteMetricValues => {
  const demandFulfilledByInRange =
    forecastedInRangeProcurement / forecastedEnergyFlowAnnual.demand;
  if (proposalType === "tenant")
    return {
      procurementCostYearOne: forecastedInRangeProcurementCost,
      procuredEnergyYearOne: forecastedInRangeProcurement,
      savingsYearOne: financialModels[proposalType].savings,
      marketTariff,
      totalDemandYearOne: forecastedEnergyFlowAnnual.demand,
      demandFulfilledByInRange,
      emissionsAvoidedYearOne: calculations.emissionsAvoided.totalAvoidance,
      cfeScore: calcCfeScore(forecastedEnergyFlowAnnual),
      hhDataUploaded,
      hasOnsiteBehindMeter,
      numNetworkImportSites,
    };

  const isLicense = investmentModel === "license";
  const isOwnerOccupier = proposalType === "ownerOccupier";

  const revenueYearOne = financialModels[proposalType][investmentModel].revenue;
  const siteMetricsValues: LandlordSiteMetricValues = {
    revenueYearOne: revenueYearOne,
    initialInvestment: isLicense ? projectCosts.initialInvestment : 0,
    lifetimeRevenue:
      financialModels[proposalType][investmentModel].lifetimeRevenue,
    totalProjectCost: isLicense ? projectCosts.total : 0,
    irr: financialModels[proposalType].license.irr,
    payback: financialModels[proposalType].license.paybackMonths,
    totalBuildingAreaM2: totalBuildingArea,
    onSiteDemandYearOne: forecastedEnergyFlowAnnual.demand,
    marketTariff,
    demandFulfilledByInRange,
    procurementCostYearOne: forecastedInRangeProcurementCost,
    generationPotentialYearOne: forecastedEnergyFlowAnnual.generation,
    onSiteGenerationConsumed: forecastedEnergyFlowAnnual.tenantEnergyShare,
    emissionsAvoidedYearOne: calculations.emissionsAvoided.totalAvoidance,
    cfeScore: calcCfeScore(forecastedEnergyFlowAnnual),
    investmentModel: investmentModel,
    hhDataUploaded,
    hasOnsiteBehindMeter,
    numNetworkImportSites,
    numNetworkExportSites,
  };

  if (isOwnerOccupier) {
    siteMetricsValues.savingsYearOne =
      financialModels[proposalType][investmentModel].savings;
  }

  return siteMetricsValues;
};

const generateTariffMetrics = (
  site: Site,
  tenantTariff: number,
  proposalType: string,
  isGeneratingSite: boolean
): SummaryBoxMetric[] | SummaryBoxMetric[][] => {
  const hasWiredBuy = site.sdmMatches.some(
    (match) => match.buyerId === site.id && match.isWired
  );

  const onsiteTariffBlock: SummaryBoxMetric = {
    value: tariffToFractionalCurrency(tenantTariff, site.currencyCode),
    valueSubText: !isGeneratingSite && hasWiredBuy ? "Wired" : "On-site",
    dataTestId: "test-tariff",
  };

  const networkImportTariffBlock: SummaryBoxMetric = {
    value: tariffToFractionalCurrency(
      site.networkImportTariff,
      site.currencyCode
    ),
    dataTestId: isGeneratingSite ? "" : "test-tariff",
    valueSubText: ["InRange network", "excl. non-commodity costs"],
  };

  const networkExportTariffBlock: SummaryBoxMetric = {
    value: tariffToFractionalCurrency(
      site.blendedExportTariff,
      site.currencyCode
    ),
    valueSubText: "Avg. export",
    dataTestId: isGeneratingSite ? "" : "test-tariff",
  };

  if (!isGeneratingSite && !hasWiredBuy) {
    return [networkImportTariffBlock];
  }

  if (proposalType === "landlord") {
    if (
      site.energyFlowAnnual.exported === 0 &&
      site.energyFlowAnnual.networkExport === 0
    ) {
      // No export, so don't show avg export tariff
      return [onsiteTariffBlock];
    } else {
      return [onsiteTariffBlock, networkExportTariffBlock];
    }
  }

  if (proposalType === "tenant") {
    return [onsiteTariffBlock, networkImportTariffBlock];
  }

  // owner occupier
  if (
    site.energyFlowAnnual.exported === 0 &&
    site.energyFlowAnnual.networkExport === 0
  ) {
    // No export, so don't show avg export tariff
    return [onsiteTariffBlock, networkImportTariffBlock];
  }

  return [
    [onsiteTariffBlock, networkImportTariffBlock],
    [networkExportTariffBlock],
  ];
};

const generateProjectCostsMetrics = (
  currencyCode: string,
  costInputsPv: CostInputsPv,
  costInputsBattery: CostInputsBattery,
  projectCosts: ProjectCosts,
  investmentModel: string
): SummaryBoxMetric[] => {
  const metrics: SummaryBoxMetric[] = [];
  const isLicense = investmentModel === "license";
  if (isLicense) {
    metrics.push({
      value: formatCurrencyToNearestTen(
        isLicense ? projectCosts.initialCostPerKWp : 0,
        currencyCode,
        0,
        false
      ),
      valueSubText: "Cost per kWp",
    });
  }
  metrics.push({
    value: formatCurrencyToNearestTen(
      isLicense ? projectCosts.pvHardware : 0,
      currencyCode,
      0,
      false
    ),
    valueSubText: "Hardware costs",
  });
  if (costInputsBattery.batterySpec) {
    metrics.push({
      value: formatCurrencyToNearestTen(
        isLicense
          ? projectCosts.batteryHardware + projectCosts.batteryInstallation
          : 0,
        currencyCode,
        0,
        false
      ),
      valueSubText: "Battery costs",
    });
  }
  metrics.push({
    value: formatCurrencyToNearestTen(
      isLicense
        ? projectCosts.pvInstallation +
            costInputsPv.enablingWorksCosts +
            costInputsPv.wiringCosts
        : 0,
      currencyCode,
      0,
      false
    ),
    valueSubText: "Installation + enabling works",
  });
  metrics.push({
    value: formatCurrencyToNearestTen(
      isLicense ? projectCosts.maintenance : 0,
      currencyCode,
      0,
      false
    ),
    valueSubText: "Lifetime O&M costs",
  });
  if (projectCosts.additionalCosts > 0) {
    metrics.push({
      value: formatCurrencyToNearestTen(
        projectCosts[isLicense ? "additionalCosts" : "leaseAdditionalCosts"],
        currencyCode,
        0,
        false
      ),
      valueSubText: "Lifetime additional costs",
    });
  }

  return metrics;
};

const generateProcurementMetricsMonetary = (
  currencyCode: string,
  forecastedBehindMeterCost: number,
  forecastedNetworkImportCost: number,
  forecastedTotalProcurementCost: number
): SummaryBoxMetric[] => {
  return [
    {
      value: formatCurrencyToNearestTen(
        forecastedBehindMeterCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "On-site generation",
    },
    {
      value: formatCurrencyToNearestTen(
        forecastedNetworkImportCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "InRange network",
    },
    {
      value: formatCurrencyToNearestTen(
        forecastedTotalProcurementCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "Total",
    },
  ];
};

const generateProcurementMetricsSource = (
  forecastedEnergyFlowAnnual: AggregateEnergyFlow,
  isGeneratingSite: boolean,
  numNetworkImportSites: number,
  setShowEnergyCurvesModal: (showModal: boolean) => void
): SummaryBoxMetric[] => {
  const forecastedBehindMeter = forecastedEnergyFlowAnnual.behindMeter;
  const forecastedNetworkImport = forecastedEnergyFlowAnnual.networkImport;
  const forecastedGridImport = forecastedEnergyFlowAnnual.gridImport;
  const demand = forecastedEnergyFlowAnnual.demand;
  const behindMeterPercent = (forecastedBehindMeter / demand) * 100;
  const networkImportPercent = (forecastedNetworkImport / demand) * 100;
  const gridImportPercent = (forecastedGridImport / demand) * 100;
  const metrics: SummaryBoxMetric[] = [
    {
      value: formatUnitsToNearestTen(forecastedBehindMeter, "kWh"),
      valueSubText: `On-site solar energy (${behindMeterPercent.toFixed(1)}%)`,
    },
    {
      value: formatUnitsToNearestTen(forecastedNetworkImport, "kWh"),
      valueSubText: (
        <>
          InRange network ({networkImportPercent.toFixed(1)}%
          {numNetworkImportSites > 0 && (
            <>
              {" "}
              from{" "}
              <InheritColorLink
                href="#"
                onClick={() => setShowEnergyCurvesModal(true)}
              >
                {numNetworkImportSites} sites
              </InheritColorLink>
            </>
          )}
          )
        </>
      ),
    },
    {
      value: formatUnitsToNearestTen(forecastedGridImport, "kWh"),
      valueSubText: `Other suppliers (${gridImportPercent.toFixed(1)}%)`,
    },
  ];

  if (!isGeneratingSite) return metrics.slice(1);

  return metrics;
};

const generateEnergySaleMetrics = (
  forecastedEnergyFlowAnnual: AggregateEnergyFlow,
  numNetworkExportSites: number,
  setShowEnergyCurvesModal: (showModal: boolean) => void,
  isSiteCanSell: boolean
): SummaryBoxMetric[] => {
  const behindMeterPercent =
    (forecastedEnergyFlowAnnual.behindMeter /
      forecastedEnergyFlowAnnual.generation) *
    100;
  const totalExport =
    forecastedEnergyFlowAnnual.networkExport +
    forecastedEnergyFlowAnnual.exported;
  const exportPercent =
    (totalExport / forecastedEnergyFlowAnnual.generation) * 100;
  const metrics: SummaryBoxMetric[] = [
    {
      value: formatUnitsToNearestTen(
        forecastedEnergyFlowAnnual.behindMeter,
        "kWh"
      ),
      valueSubText: `On-site (${behindMeterPercent.toFixed(1)}%)`,
    },
    {
      value: formatUnitsToNearestTen(totalExport, "kWh"),
      valueSubText: (
        <>
          InRange network ({exportPercent.toFixed(1)}%
          {numNetworkExportSites > 0 &&
            (isSiteCanSell ? (
              <>
                {" "}
                to{" "}
                <InheritColorLink
                  href="#"
                  onClick={() => setShowEnergyCurvesModal(true)}
                >
                  {numNetworkExportSites} sites
                </InheritColorLink>
              </>
            ) : (
              <> to {numNetworkExportSites} sites</>
            ))}
          )
        </>
      ),
    },
  ];

  return metrics;
};

const generateTenantLandlord = (
  landlordName: string | undefined
): SummaryBoxMetric[] => {
  return [{ value: landlordName ?? "N/A", valueSubText: undefined }];
};
