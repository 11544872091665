import React from "react";

interface TableHeaderCellProps {
  handleColumnClick: (columnName: string) => void;
  columnName: string;
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
  HeaderComponent: React.FC<HeaderComponentProps>;
  displayInRangeScoreCol?: boolean;
  orgAreaUnit?: string;
}

export interface HeaderComponentProps {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
  displayInRangeScoreCol?: boolean;
  orgAreaUnit?: string;
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  handleColumnClick,
  columnName,
  sortColumn,
  sortAscending,
  sortValue,
  HeaderComponent,
  displayInRangeScoreCol,
  orgAreaUnit,
}) => (
  <th
    onClick={() => {
      handleColumnClick(columnName);
    }}
    data-testid={`table-header-${columnName}`}
  >
    <HeaderComponent
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      sortValue={sortValue}
      displayInRangeScoreCol={displayInRangeScoreCol}
      orgAreaUnit={orgAreaUnit}
    />
  </th>
);
