export const getProposalType = (
  orgType: string,
  isGeneratingSite: boolean,
  isOwnerOccupier: boolean
) => {
  const isLandlord = orgType === "Landlord";
  // treat non generating sites as tenants
  return isGeneratingSite
    ? isOwnerOccupier
      ? "ownerOccupier"
      : isLandlord
        ? "landlord"
        : "tenant"
    : "tenant";
};
