import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";

export const siteScoresData: Record<string, Record<string, number>> = {
  "b4e2dec0-b64d-4b24-0org-aaaabbbb0000": {
    "b4e2dec0-b64d-4b24-site-aaaabbbb0000": 4,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0001": 4,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0002": 4,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0003": 3,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0004": 3,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0005": 3,
  },
};

export const inrangeScoreStatusColor: Record<number, string> = {
  5: "#01B28A",
  4: "#3E6D4F",
  3: "#A7923F",
  2: "#AD7A2D",
  1: "#4077A3",
};

export function getScoreBySiteID(
  orgID: string,
  site: OrgSiteListEntry | Site
): number | undefined {
  if (site.score !== undefined && site.score !== null) {
    return site.score;
  }
  if (siteScoresData[orgID]) {
    if (siteScoresData[orgID][site.id] !== undefined) {
      return siteScoresData[orgID][site.id];
    }
  }
  if (site.id.startsWith("e2e-score")) {
    const score = parseInt(site.id.split("-")[2]); // In our e2e tests, when testing InRange score, we prefix site ids with "e2e-score-<score>"
    return score;
  }
  return undefined;
}

export function isOrganizationInSiteScores(
  orgID: string,
  sites: OrgSiteListEntry[]
): boolean {
  // In our e2e tests, when testing InRange score, we prefix org ids with "e2e-score"
  if (orgID.startsWith("e2e-score")) {
    return true;
  }
  if (sites.some((site) => site.score !== null && site.score !== undefined)) {
    return true;
  }
  return orgID in siteScoresData;
}
