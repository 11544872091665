import styled from "styled-components";
import Tooltip from "../Tooltip";
import { getSortingIcon } from "./getSortingIcon";

export const LastMonthCostHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Cost procured prev month
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The cost of energy procured from InRange in the month previous to the current month."></Tooltip>
    </ColumnContainer>
  );
};

export const LastMonthProcurementHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
}) => {
  return (
    <ColumnTextStyle>
      Amount procured prev month
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
      <Tooltip text="The amount of energy procured from InRange in the month previous to the current month."></Tooltip>
    </ColumnTextStyle>
  );
};

export const LastMonthRevenueHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Revenue prev month
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip
        text={
          "The revenue generated in the month previous to the current month. Based on turnover rent - a percentage of operating profit derived from the on-site and export PPAs."
        }
      ></Tooltip>
    </ColumnContainer>
  );
};

export const SiteHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
}) => {
  return (
    <ColumnTextStyle>
      Site
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const LastMonthSavingsHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Savings prev month
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip
        text={`The amount saved relative to the market rate in the month previous to the current month.`}
      ></Tooltip>
    </ColumnContainer>
  );
};

export const LastMonthGenerationHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Generated prev month
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The total amount of on-site energy generated in kWh in the month previous to the current month."></Tooltip>
    </ColumnContainer>
  );
};

export const LastMonthDemandFulfilledHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Demand fulfilled prev month
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>

      <Tooltip
        text={
          "The percentage of total behind the meter demand fulfilled by the on-site generating system in the month previous to the current month."
        }
      ></Tooltip>
    </ColumnContainer>
  );
};

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnTextStyle = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`;
