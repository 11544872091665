import React from "react";
import styled from "styled-components";
import { HeaderComponentProps } from "./TableHeaderCell";
import { getSortingIcon } from "./getSortingIcon";

// header for description, date, invoiceStatementNumber, invoiceStatementAmount, invoiceDueDate

export const DescriptionHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Description
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
    </ColumnContainer>
  );
};

export const InvoiceStatementNumberHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      {"Invoice/statement #"}
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const InvoiceStatementAmountHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Invoice/statement amount
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const InvoiceDueDateHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Due date
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const IssueDateHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Issue date
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const DateHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Date
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnTextStyle = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`;
