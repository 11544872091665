import React from "react";

import {
  useNetworkSiteList,
  useOrgListUsers,
  useOrganisation,
  useSite,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import { usePvGeoJson } from "@inrange/inrange-data-api-client";
import {
  BreadCrumb,
  ErrorSidebar,
  Loaded,
  Loading,
  ShareAttributes,
  SidebarWithHeader,
  SimpleButton,
  SiteViewHeader,
  getScoreBySiteID,
  saveSessionStoredValue,
} from "@inrange/theme-components";
import {
  siteCanBuy,
  siteCanOnlySellThroughWire,
  siteCanSell,
  siteHasExistingWiredMatch,
} from "@inrange/theme-components/marketplace";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../auth/UserContext";
import calcIsOwnerOccupied from "../utils/calcIsOwnerOccupied";
import EnergySavings from "./SiteViewTabs/EnergySavings";
import FileList from "./SiteViewTabs/FileList";
import OperationalSummaryLandlord from "./SiteViewTabs/OperationalSummaryLandlord";
import OperationalSummaryOwnerOccupier from "./SiteViewTabs/OperationalSummaryOwnerOccupier";
import ProposalSummary from "./SiteViewTabs/ProposalSummary";
import PvSystemView from "./SiteViewTabs/PvSystem";
import { countSitesByOwnershipAndOperationalStatus } from "./dashboard/aggregationCalculations";

const SiteView: React.FC = () => {
  const { logout } = useKindeAuth();
  const location = useLocation();
  const { orgId, siteId } = useParams<{ orgId: string; siteId: string }>();
  const { fetchOrganisation, useOrganisationSites } = useOrganisation(
    orgId,
    orgId
  );
  const { user } = useContext(UserContext);
  const { fetchSite } = useSite({
    siteId,
    userOrgId: orgId,
    app: "customer",
  });
  // Prefetch network sites so that when the user clicks through to the marketplace
  // the data is already loaded
  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  useNetworkSiteList(
    orgId,
    fetchOrganisation.isSuccess && fetchSite.isSuccess,
    testMode,
    orgId
  );

  const [investmentModel, setInvestmentModel] = useState<string | undefined>(
    fetchSite.data?.site?.activeInvestmentModel
  );

  const organisation = fetchOrganisation.data?.organisation;
  const organisationSiteListSites = fetchOrganisation.data?.sites;
  const site = fetchSite.data?.site;
  // Prefetch panel data
  usePvGeoJson(siteId, site?.pvPanel);
  // Trigger fetching of all sites for the organisation eagerly so that they are more
  // likely to be pre-loaded when the user navigates to any of them
  useOrganisationSites(
    fetchOrganisation.isSuccess && fetchSite.isSuccess
      ? organisation?.siteOwnerships
      : []
  );

  const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
  const orgType = organisation?.orgType;

  const shareOrgType = isOwnerOccupier
    ? undefined
    : orgType === "Tenant"
      ? "landlord"
      : "tenant";
  const shareOrgData = site?.siteOwnerships.filter(
    (ownershipData) => ownershipData.ownership === shareOrgType
  );
  const shareOrgIds = shareOrgData
    ? shareOrgData.map((data) => data.orgID)
    : [];
  const { orgListUsers: shareOrgUsers, addOrganisationUser: addShareOrgUser } =
    useOrgListUsers(
      // We compute shareOrgIds from the site record, so this request wont happen until
      // after the site is loaded
      shareOrgIds,
      site?.id,
      orgId
    );
  const { addOrganisationUser: addCurrentOrgUser } = useOrgListUsers(
    [orgId],
    // We don't need to pass the siteId to be allowed to get the current user's org's users
    undefined,
    orgId
  );

  useEffect(() => {
    document.body.scrollTop = 0;
    if (site && !investmentModel) {
      setInvestmentModel(site.activeInvestmentModel);
    }
  }, [site, investmentModel]);

  if (fetchOrganisation.isError) {
    return (
      <ErrorSidebar onClickLogout={logout}>
        <Loaded>Organisation not found</Loaded>
      </ErrorSidebar>
    );
  }

  if (fetchSite.isError) {
    return (
      <ErrorSidebar onClickLogout={logout}>
        <Loaded>
          Site not found
          <SimpleButton
            fontWeight="600"
            label="Go to dashboard"
            to={`/org/${orgId}/dashboard`}
          />
        </Loaded>
      </ErrorSidebar>
    );
  }

  if (
    fetchOrganisation.isLoading ||
    !organisation ||
    !organisationSiteListSites ||
    fetchSite.isLoading ||
    !site ||
    !investmentModel
  ) {
    return <Loading label="Loading your data..." />;
  }

  const currentUrl = location.pathname;
  const rootPath = `/org/${orgId}/`;

  const siteCounts = countSitesByOwnershipAndOperationalStatus(
    organisationSiteListSites,
    organisation.siteOwnerships
  );
  const isOperational = site.operationalStatus === "operational";

  const orgName = organisation?.name;
  const siteName = site?.name;
  document.title = orgName
    ? `InRange - ${orgName} - ${siteName}`
    : document.title;

  const irr =
    site.financialModels[isOwnerOccupier ? "ownerOccupier" : "landlord"].license
      .irr;

  const paybackMonths =
    site.financialModels[isOwnerOccupier ? "ownerOccupier" : "landlord"].license
      .paybackMonths;

  const score = getScoreBySiteID(orgId!, site);

  const pvSystemSelected = !!site.pvDesignSystemLastUpdatedAt;
  const isGeneratingSite = site.energyFlowAnnual.generation > 0;
  const isNotYetProcuringSite =
    site.energyFlowAnnual.behindMeter + site.energyFlowAnnual.networkImport ===
      0 && site.energyFlowAnnual.demand > 0;

  let energyOffer:
    | { label: string; acceptOfferFn: () => void; acceptOfferUrl: string }
    | undefined;
  if (siteCanBuy(organisation, site) || siteCanSell(organisation, site)) {
    if (
      (orgType === "Tenant" && !isOwnerOccupier) ||
      (isOwnerOccupier &&
        (site.activeInvestmentModel === "lease" ||
          site.installedCapacity === 0))
    ) {
      energyOffer = {
        label: `Buy ${isNotYetProcuringSite ? "" : "more"} energy`,
        acceptOfferFn: () => {
          trackUserInteraction(
            {
              organisation_name: organisation.name,
              site_id: site.id,
              site_name: site.name,
              site_operational_status: site.operationalStatus,
              label: `Buy ${isNotYetProcuringSite ? "" : "more"} energy`,
              button_location: "top nav",
            },
            "ENERGY_OFFER",
            "ENERGY_OFFER_SITE_BUTTON_CLICK",
            user!.email.toLowerCase(),
            "customer-app"
          );
        },
        acceptOfferUrl: `/org/${organisation.id}/marketplace?siteId=${site.id}&offerType=buy`,
      };
    } else if (site.installedCapacity > 0) {
      energyOffer = {
        label: siteCanOnlySellThroughWire(site)
          ? `Sell ${siteHasExistingWiredMatch(site) ? "more " : ""}within park`
          : "Sell more energy",
        acceptOfferFn: () => {
          trackUserInteraction(
            {
              organisation_name: organisation.name,
              site_id: site.id,
              site_name: site.name,
              site_operational_status: site.operationalStatus,
              label: siteCanOnlySellThroughWire(site)
                ? `Sell ${siteHasExistingWiredMatch(site) ? "more " : ""}within park`
                : "Sell more energy",
              button_location: "top nav",
            },
            "ENERGY_OFFER",
            "ENERGY_OFFER_SITE_BUTTON_CLICK",
            user!.email.toLowerCase(),
            "customer-app"
          );
        },
        acceptOfferUrl: `/org/${organisation.id}/marketplace?siteId=${site.id}&offerType=sell`,
      };
    }
  }

  const shareOrgNames = shareOrgData!.map((data) => data.name);
  const shareOrgName =
    shareOrgNames.length === 1 ? shareOrgNames[0] : undefined;

  const shareOrgUserEmails: string[] = [];
  shareOrgUsers.forEach((query) => {
    if (query.isSuccess) {
      shareOrgUserEmails.push(...query.data.user_emails);
    }
  });

  const currentOrgUserEmails = organisation.userEmails;

  async function handleAddUser(email: string, shareType: string) {
    if (!email) return null;

    if (shareType !== "withinOrg") {
      if (shareOrgIds.length !== 1) {
        trackUserInteraction(
          {
            invitee_organisation_email: email,
            invitee_organisation_id: undefined,
            inviter_organisation_email: user!.email,
            inviter_organisation_id: organisation!.id,
            type: shareType,
            multiple_orgs: shareOrgIds.length > 1,
            site_id: site!.id,
            site_name: site!.name,
            site_operational_status: site!.operationalStatus,
          },
          "BASIC_ACTION",
          "INVITE_CREATED",
          user!.email.toLowerCase(),
          "customer-app"
        );
        return;
      }
    }

    const addFunction =
      shareType === "withinOrg" ? addCurrentOrgUser : addShareOrgUser;
    const shareOrgId =
      shareType === "withinOrg" ? organisation!.id : shareOrgIds[0];

    await addFunction.mutateAsync(
      {
        email: email,
        siteId: site!.id,
        orgId: shareOrgId,
      },
      {
        onSuccess: () => {
          trackUserInteraction(
            {
              invitee_organisation_email: email,
              invitee_organisation_id: shareOrgId,
              inviter_organisation_email: user!.email,
              inviter_organisation_id: organisation!.id,
              type: shareType,
              site_id: site!.id,
              site_name: site!.name,
              site_operational_status: site!.operationalStatus,
            },
            "BASIC_ACTION",
            "INVITE_CREATED",
            user!.email.toLowerCase(),
            "customer-app"
          );
        },
      }
    );
  }

  const shareAttributes = {
    shareOrgUserEmails,
    currentOrgUserEmails,
    userEmailsIsLoading:
      addShareOrgUser.isLoading || addCurrentOrgUser.isLoading,
    addUser: handleAddUser,
    onShareButtonClick: (type: string) => {
      trackUserInteraction(
        {
          organisation_name: organisation.name,
          site_id: site.id,
          site_name: site.name,
          site_operational_status: site.operationalStatus,
          type: type,
        },
        "BASIC_ACTION",
        "SHARE_SITE_CLICK",
        user!.email.toLowerCase(),
        "customer-app"
      );
    },
    shareOrgName,
  };

  if (isOperational) {
    return (
      <SiteViewOperational
        organisation={organisation}
        site={site}
        siteCounts={siteCounts}
        currentUrl={currentUrl}
        userEmail={user!.email}
        rootPath={rootPath}
        showPvSystemTab={pvSystemSelected && isGeneratingSite}
        isOwnerOccupier={isOwnerOccupier!}
        orgType={orgType!}
        energyOffer={energyOffer}
        showEnergySavingsTab={false}
        shareAttributes={shareAttributes}
      />
    );
  }

  return (
    <SiteViewProposal
      organisation={organisation}
      orgType={orgType!}
      site={site}
      siteCounts={siteCounts}
      currentUrl={currentUrl}
      userEmail={user!.email}
      rootPath={rootPath}
      irr={irr}
      totalBuildingAreaM2={site.totalBuildingArea}
      paybackMonths={paybackMonths}
      energyOffer={energyOffer}
      showPvSystemTab={pvSystemSelected && isGeneratingSite}
      isOwnerOccupier={isOwnerOccupier!}
      isGeneratingSite={isGeneratingSite}
      isNotYetProcuringSite={isNotYetProcuringSite}
      showEnergySavingsTab={true}
      score={score}
      investmentModel={investmentModel}
      setInvestmentModel={setInvestmentModel}
      shareAttributes={shareAttributes}
    />
  );
};

export default SiteView;

interface SiteViewProposalProps {
  organisation: Organisation;
  orgType: string;
  site: Site;
  siteCounts: Record<string, number>;
  currentUrl: string;
  userEmail: string;
  rootPath: string;
  energyOffer?: {
    label: string;
    acceptOfferFn: () => void;
    acceptOfferUrl: string;
  };
  showPvSystemTab: boolean;
  isOwnerOccupier: boolean;
  isGeneratingSite: boolean;
  isNotYetProcuringSite: boolean;
  irr?: number;
  totalBuildingAreaM2: number;
  paybackMonths?: number;
  showEnergySavingsTab: boolean;
  score?: number;
  investmentModel: string;
  setInvestmentModel: (model: string) => void;
  shareAttributes: ShareAttributes;
}

const SiteViewProposal: React.FC<SiteViewProposalProps> = ({
  organisation,
  orgType,
  site,
  siteCounts,
  currentUrl,
  userEmail,
  rootPath,
  energyOffer,
  showPvSystemTab,
  isOwnerOccupier,
  isGeneratingSite,
  isNotYetProcuringSite,
  irr,
  totalBuildingAreaM2,
  paybackMonths,
  showEnergySavingsTab,
  score,
  investmentModel,
  setInvestmentModel,
  shareAttributes,
}) => {
  const { logout } = useKindeAuth();
  const { currentTab } = useParams<{ currentTab: string }>();
  const { user, onOrgSwitch } = useContext(UserContext);

  const kindeOrgCode = user!.kinde_org_code;
  const kindeOrgs = user!.kinde_orgs;

  let tab = (
    <ProposalSummary
      site={site}
      organisation={organisation}
      irr={irr}
      totalBuildingAreaM2={totalBuildingAreaM2}
      paybackMonths={paybackMonths}
      investmentModel={investmentModel}
      setInvestmentModel={setInvestmentModel}
      isOwnerOccupier={isOwnerOccupier}
      isGeneratingSite={isGeneratingSite}
      isNotYetProcuringSite={isNotYetProcuringSite}
      orgType={orgType}
    />
  );

  if (currentTab === "pv-system") {
    tab = <PvSystemView organisation={organisation} site={site} />;
  }

  if (currentTab === "energy-savings") {
    tab = (
      <EnergySavings
        organisation={organisation}
        site={site}
        investmentModel={investmentModel}
        isOwnerOccupier={isOwnerOccupier}
        isGeneratingSite={isGeneratingSite}
      />
    );
  }

  const crumbs: BreadCrumb[] = [
    {
      label: `${organisation.name} Portfolio`,
      url: `${rootPath}dashboard`,
    },
    { label: site.name },
  ];
  const ownership = site.siteOwnerships.find(
    (so) => so.orgID === organisation.id
  );
  if (orgType === "Landlord" && ownership && ownership.park) {
    crumbs.splice(1, 0, {
      label: ownership.park!,
      url: `${rootPath}dashboard`,
      onClick: () => {
        saveSessionStoredValue(organisation.id, "landlord-park", [
          ownership.park!,
        ]);
      },
    });
  }

  return (
    <SidebarWithHeader
      crumbs={crumbs}
      currentUrl={currentUrl}
      userEmail={userEmail}
      rootPath={rootPath}
      orgType={orgType}
      siteCounts={siteCounts}
      onClickLogout={logout}
      score={score}
      onOrgSwitch={onOrgSwitch!}
      userOrgs={kindeOrgs}
      currentKindeOrg={kindeOrgCode}
      siteStatus={site.operationalStatus}
      siteOperationalDateEpochSeconds={
        site.commerciallyOperationalDateEpochSeconds
      }
    >
      <SiteViewHeader
        siteId={site.id}
        currentUrl={currentUrl}
        rootPath={rootPath}
        orgType={orgType}
        shareAttributes={shareAttributes}
        isOwnerOccupier={isOwnerOccupier}
        isOperational={site.operationalStatus === "operational"}
        energyOffer={energyOffer}
        showPvSystemTab={showPvSystemTab}
        showEnergySavingsTab={showEnergySavingsTab}
      />
      {tab}
    </SidebarWithHeader>
  );
};

interface SiteViewOperationalProps {
  organisation: Organisation;
  site: Site;
  siteCounts: Record<string, number>;
  currentUrl: string;
  userEmail: string;
  rootPath: string;
  isOwnerOccupier: boolean;
  orgType: string;
  energyOffer?: {
    label: string;
    acceptOfferFn: () => void;
    acceptOfferUrl: string;
  };
  showPvSystemTab: boolean;
  showEnergySavingsTab: boolean;
  shareAttributes: ShareAttributes;
}

const SiteViewOperational: React.FC<SiteViewOperationalProps> = ({
  organisation,
  site,
  siteCounts,
  currentUrl,
  userEmail,
  rootPath,
  isOwnerOccupier,
  orgType,
  energyOffer,
  showPvSystemTab,
  showEnergySavingsTab,
  shareAttributes,
}) => {
  const { logout } = useKindeAuth();
  const { user, onOrgSwitch } = useContext(UserContext);
  const { currentTab } = useParams<{ currentTab: string }>();

  const kindeOrgCode = user!.kinde_org_code;
  const kindeOrgs = user!.kinde_orgs;

  let tab =
    orgType === "Landlord" ? (
      <OperationalSummaryLandlord site={site} />
    ) : (
      <OperationalSummaryOwnerOccupier site={site} />
    );

  if (currentTab === "pv-system") {
    tab = <PvSystemView organisation={organisation} site={site} />;
  }

  if (currentTab === "billings-and-statements") {
    tab = (
      <FileList
        orgId={organisation.id}
        site={site}
        startingSortColumn="issueDate"
      />
    );
  }

  const crumbs: BreadCrumb[] = [
    {
      label: `${organisation.name} Portfolio`,
      url: `${rootPath}dashboard`,
    },
    { label: site.name },
  ];
  const ownership = site.siteOwnerships.find(
    (so) => so.orgID === organisation.id
  );
  if (orgType === "Landlord" && ownership && ownership.park) {
    crumbs.splice(1, 0, {
      label: ownership.park!,
      url: `${rootPath}dashboard`,
      onClick: () => {
        saveSessionStoredValue(organisation.id, "landlord-park", [
          ownership.park!,
        ]);
      },
    });
  }

  return (
    <SidebarWithHeader
      crumbs={crumbs}
      currentUrl={currentUrl}
      userEmail={userEmail}
      rootPath={rootPath}
      orgType={orgType}
      siteCounts={siteCounts}
      onClickLogout={logout}
      onOrgSwitch={onOrgSwitch!}
      userOrgs={kindeOrgs}
      currentKindeOrg={kindeOrgCode}
    >
      <SiteViewHeader
        siteId={site.id}
        currentUrl={currentUrl}
        rootPath={rootPath}
        orgType={orgType}
        shareAttributes={shareAttributes}
        isOwnerOccupier={isOwnerOccupier}
        isOperational={site.operationalStatus === "operational"}
        energyOffer={energyOffer}
        showPvSystemTab={showPvSystemTab}
        showEnergySavingsTab={showEnergySavingsTab}
      />
      {tab}
    </SidebarWithHeader>
  );
};
