import styled from "styled-components";
import { Row } from "../layout/Flex";
import Tooltip from "../Tooltip";
import { getSortingIcon } from "./getSortingIcon";

export const PaybackHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Payback
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The number of years required to pay back the up front investment given the expected annual revenue."></Tooltip>
    </ColumnContainer>
  );
};

export const TenantsHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
}) => {
  return (
    <ColumnTextStyle>
      Tenants
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const IRRHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        IRR
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="IRR is calculated based on expected revenue as it relates to project cost."></Tooltip>
    </ColumnContainer>
  );
};

export const RevenueHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Revenue <br />
        (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip
          text={"Estimated revenue generated in the first year."}
        ></Tooltip>
      </Row>
    </ColumnContainer>
  );
};

export const SiteHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
}) => {
  return (
    <ColumnTextStyle>
      Site
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const CarbonSavingsHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Carbon Savings
        <br />
        (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip
          text={
            "Emissions avoided in the first year where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at this site location."
          }
        ></Tooltip>
      </Row>
    </ColumnContainer>
  );
};

export const SiteStatusHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
  displayInRangeScoreCol,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
  displayInRangeScoreCol?: boolean;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle
        style={{
          width: `${(110 + (displayInRangeScoreCol ? 20 : 0)).toString()}px`,
        }}
      >
        {`Site status${displayInRangeScoreCol ? " / score" : ""}`}
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      {displayInRangeScoreCol && (
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          <Tooltip
            text={
              "InRange score is calculated based on a number of factors, including the site’s savings potential, the likelihood of sufficient network availability, proximity of other InRange sites, and the characteristics of other sites in your portfolio."
            }
          />
        </Row>
      )}
    </ColumnContainer>
  );
};

export const InitialInvestmentHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Up front investment
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The up front investment for the site including hardware and installation."></Tooltip>
    </ColumnContainer>
  );
};

export const NetworkAvailabilityHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}: {
  sortColumn: string;
  sortValue: string;
  sortAscending: boolean;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Network Availability
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>

      <Tooltip
        text={
          "The projected network availability at the substation for this site.\nAvailable: Expected to have sufficient capacity for your estimated generation.\nUnavailable: Expected to not have sufficient capacity for your estimated generation.\nComing soon: Insufficient data, pending DNO engagement.\nSubject to change based on DNO capacity changes."
        }
      ></Tooltip>
    </ColumnContainer>
  );
};

export const RevenuePerAreaHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
  orgAreaUnit,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
  orgAreaUnit?: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Revenue per {orgAreaUnit ?? "ft"}² <br /> (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip
          text={`Estimated revenue generated in the first year divided by the size of the roof in ${orgAreaUnit}2.`}
        ></Tooltip>
      </Row>
    </ColumnContainer>
  );
};

export const RooftopLeasedHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}: {
  sortColumn: string;
  sortAscending: boolean;
  sortValue: string;
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Rooftop Leased
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="Total rooftop space available on this site to be leased by InRange."></Tooltip>
    </ColumnContainer>
  );
};

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnTextStyle = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`;
