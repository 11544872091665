import React from "react";
import { HeaderComponentProps, TableHeaderCell } from "./TableHeaderCell";
import {
  CarbonSavingsHeader,
  EnergyProcuredHeader,
  LandlordsHeader,
  SavingsHeader,
  SiteHeader,
  SiteStatusHeader,
} from "./TenantSiteHeaderTooltip";

import {
  DemandFulfillment,
  NetworkAvailabilityHeader,
} from "./TenantSiteHeaderTooltip";

interface HeaderData {
  columnName: string;
  sortValue: string;
  HeaderComponent: React.FC<HeaderComponentProps>;
}

const TenantSiteHeader: React.FC<{
  handleColumnClick: (columnName: string) => void;
  sortColumn: string;
  sortAscending: boolean;
  displayInRangeScoreCol: boolean;
  allSitesNotStarted: boolean;
}> = ({
  handleColumnClick,
  sortColumn,
  sortAscending,
  displayInRangeScoreCol,
  allSitesNotStarted,
}) => {
  const headerData: HeaderData[] = displayInRangeScoreCol
    ? [
        { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
        {
          columnName: "annualSavings",
          sortValue: "annualSavings",
          HeaderComponent: SavingsHeader,
        },
        {
          columnName: "energyProcured",
          sortValue: "energyProcured",
          HeaderComponent: EnergyProcuredHeader,
        },
        {
          columnName: "landlords",
          sortValue: "landlords",
          HeaderComponent: LandlordsHeader,
        },
        {
          columnName: "carbonSavings",
          sortValue: "carbonSavings",
          HeaderComponent: CarbonSavingsHeader,
        },
      ]
    : [
        { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
        {
          columnName: "networkAvailabilityTenant",
          sortValue: "networkAvailabilityTenant",
          HeaderComponent: NetworkAvailabilityHeader,
        },
        {
          columnName: "annualSavings",
          sortValue: "annualSavings",
          HeaderComponent: SavingsHeader,
        },
        {
          columnName: "energyProcured",
          sortValue: "energyProcured",
          HeaderComponent: EnergyProcuredHeader,
        },
        {
          columnName: "netUsage",
          sortValue: "netUsage",
          HeaderComponent: DemandFulfillment,
        },
        {
          columnName: "carbonSavings",
          sortValue: "carbonSavings",
          HeaderComponent: CarbonSavingsHeader,
        },
      ];

  const hideStatusColumn = allSitesNotStarted && !displayInRangeScoreCol;

  if (!hideStatusColumn) {
    headerData.unshift({
      columnName: "siteStatusTenant",
      sortValue: "siteStatusTenant",
      HeaderComponent: SiteStatusHeader,
    });
  }

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
          displayInRangeScoreCol={displayInRangeScoreCol}
        />
      ))}
      <th></th>
    </tr>
  );
};

export default TenantSiteHeader;
